import React from 'react';
import appStyles from './App.module.css';

function App() {
  return (
    <div className={appStyles.bodyClass}>Hello World!</div>
  );
}

export default App;
